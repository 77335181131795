<template>
    <div class="px-3 py-4 bg-dark">
        <h1 class="text-danger">
            <i class="fas fa-book mr-2 my-1"></i>
                {{
                    $store.state.language === "en"
                        ? "Rules And Regulations"
                        : "စည်းကမ်းချက်များ"
                }}
            </h1>
        <ul class="text-white font-weight-300">
            <!-- <li class="my-4">
                <span class="text-danger"> (၁) </span> ဘော်ဒီ ၊ ဂိုးပေါင်း ၊ စုံမ ပွဲစဉ်များကို (⭐)  ဖြင့် ပွဲကြီး ပွဲသေး  ခွဲခြားထားပြီး
            </li> -->
            <li class="my-4">
                <span class="text-danger mr-3"> (၁) </span>ဘော်ဒီ ၊ ဂိုးပေါင်း ၊ စုံမ ပွဲစဉ်များကို (⭐) ဖြင့် ပွဲကြီး / ပွဲသေး ခွဲခြားထားပြီး <br> <br>
                 <span class="ml-4">ပွဲကြီး အကောက် - <span class="text-danger">5%</span> ( ၁၀၀၀ ယူနစ်မှ ၁၅ သိန်း ယူနစ် အထိ )</span> <br>
                 <span class="ml-4">ပွဲသေး အကောက် - <span class="text-danger">7%</span> ( ၁၀၀၀ ယူနစ်မှ ၃ သိန်း ယူနစ်အထိ ) </span> <br>
                 <span class="ml-4">ဆော့ကစားနိုင်ပါသည်။</span>
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၂) </span>မောင်းများကို အနည်းဆုံး ၂ ပွဲမှ ၁၂ ပွဲအထိ ဆော့ကစားနိုင်ပြီး ၊ မောင်းတစ်ရွက်လျှင် ၅၀၀ ယူနစ်မှ ၂ သိန်း ယူနစ်အထိ ဆော့ကစားနိုင်ပါသည်။ ( ပွဲမလာ ၊ ပွဲပျက် ၊ PP ပွဲများပါခဲ့လျှင် ကျန်ပွဲဖြင့် အလျော်အစားပြုလုပ်ပါမည်။ )
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၃) </span>၂သင်းမှ ၈သင်းထိ အကောက် - <span class="text-danger">16 %</span> နှင့်  ၉ သင်း နှင့်အထက် - <span class="text-danger"> 20%</span> ကောက်ခံပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၄) </span>ရွေးချယ်ထားသောမောင်းများမှ ပွဲစဉ်များအားလုံး မကန်ဖြစ်မှသာ လောင်းကြေးငွေအား ပြန်လည်ရရှိမည် ဖြစ်ပါသည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၅) </span> သတ်မှတ်ရက်အတွင်း နောက်တစ်နေ့ 10:30am အတွင်း result (ပွဲစဉ်မပြီးသော) မထွက်သေးသောပွဲစဉ်များအား အလျော်အစား ပြုလုပ်ပေး မည်မဟုတ်ပါ။
            </li>
            <!-- <li class="my-4">
                <span class="text-danger mr-3"> (၆) </span> သတ်မှတ်ရက်အတွင်း နောက်တစ်နေ့ 10:30am အတွင်း result (ပွဲစဥ်မပြီးသော) မထွက်သေးသောပွဲစဥ်များအား အလျော်အစား ပြုလုပ်ပေး မည်မဟုတ်ပါ။
            </li> -->
            <li class="my-4">
                <span class="text-danger mr-3"> (၆) </span> ဂိုးရလာဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting company (ibet789,bet365,sportbook365) များမှထွက်ရှိသောရလာဒ်များ ဖြင့်သာ အလျော်အစားပြုလုပ်ပြီး အဆိုပါကုမ္မဏီများမှ 9:00am အထိ မထွက်ရှိပါက flashscore..com, livescore..com တို့အား ကြည့်ရှူပြီး မတွေ့ရှိပါက သက်ဆိုင်ရာအဖွဲ့ချုပ်၏ ရလာဒ်များကိုသာအတည်ပြုပါသည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၇) </span>ဂိုးရလာဒ်များထည့်သွင်းရာတွင်အချို့ပွဲစဥ်များသည် ညပိုင်းတွင်ရလာဒ်တစ်မျိုးထွက်ပေါ်ပြီး မနက်ပိုင်းတွင်ရလာဒ်တစ်မျိုးဖြင့် ပြင်ဆင်ပြောင်းလဲထွက်ပေါ်လာပါက နေ့ခင်း ၁၂ နာရီအထိသာပေးပို့အကြောင်းကြားလာသည့်ဘောင်ချာများကိုသာပြင်ဆင်ပြောင်းလဲ ပေးပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၈) </span>အချိန်စောကန်သွားသော ပွဲစဥ်များရှိပါက အခြား Betting company များနည်းတူမဟုတ်ပဲ Aon637 တွင်မူလအချိန်အတိုင်း ဖွင့်လှစ်ထားမိပါက အဆိုပါပွဲများအား Cancel match ပြုလုပ်ပါမည်။အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။
            </li>

            <li class="my-4">
                <span class="text-danger mr-3"> (၉) </span>ပုံမှန်မဟုတ်သော ပွဲစဥ်များ ပါရှိပါက ၄င်းပွဲစဥ်များအား အလျော်အစားမပြုလုပ်ပဲ Cancel Match ပြုလုပ်ပြီး လောင်းကြေးငွေ ပြန်အမ်း ပေးပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၁၀) </span>သာမန်ကစားခြင်းမဟုတ်ပဲ ကြေးအဟ သီးသန့်ကြားထိုး ကစားပါက အဆိုပါ User ၏ကြားထိုး ကစားကြောင်းအား reject ပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၁၁) </span>နည်းပညာချွတ်ယွင်း၍ အခြားသော Betting Company များနှင့်စျေးနှုန်း ပေါက်ကြေး များ (လွန်ကဲစွာ) ကွဲလွဲဖွင့်လှစ်မိပါက ယင်းစျေးနှုန်း ဖြင့် ကစားထားသော မောင်းနှင့်ဘော်ဒီများကို Cancel ပြုလုပ်ပြီး အရင်းငွေများကိုပြန်ပေးပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၁၂) </span>အငြင်းပွားဖွယ်ရာ ကိစ္စရပ်များပေါ်ပေါက်လာပါက အများနှင့် နှိုင်းယှဥ်သုံးသပ်ရသည့်အတွက် Aon637 ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်ပါသည်။
            </li>
            <li >
                <h5 class="text-center text-danger mt-4 mb-5 pb-3">
                    စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။
                </h5>
            </li>
            <li>
                <div class="d-flex justify-content-center my-4" style="position: fixed; bottom: 0; left: 0; right: 0;">
                    <button class="btn btn-danger col-4" style="font-size: 1rem; background: black; color: white !important;" @click="logout">အတည်မပြုပါ</button>
                    <button class="btn col-4" style="font-size: 1rem; background: #009646 !important; color: white !important;" @click="toHome">အတည်ပြုပါသည်</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logoutUser']),
    logout() {
      this.logoutUser();
    },
    toHome() {
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
.btn{
    background-color: #FA0500 !important;
    border-color: #FA0500 !important;
}
</style>
