<template>
    <div style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/icons/bg.png'); min-height: 100vh; height: auto; background-size: cover; padding-bottom: 10rem; background-attachment: fixed; ">
      <div class="col-lg-7 col-12 mx-auto p-0 d-flex main-image-wrapper">
          <b-carousel
              id="carousel-1"
              :interval="6000"
              controls
              fade
              style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%; padding: 10px;"
              v-if="!threedData.embedded_link && !threedData.isLive"
          >
          <!-- <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/fd42adc2-98b7-473a-e360-86cfe21bbe00/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4394f2c9-274b-4d48-3639-98789108a800/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2c472e40-74bd-4b1e-5837-1e358ef2df00/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f9d3fb5a-3256-4185-ca8e-9a58165f9a00/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/775363b3-00f6-4964-871f-421a518a8600/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide> -->
            <b-carousel-slide
                img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/banners/IMG_3451.PNG"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4394f2c9-274b-4d48-3639-98789108a800/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/banners/IMG_0020.JPG"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f9d3fb5a-3256-4185-ca8e-9a58165f9a00/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/775363b3-00f6-4964-871f-421a518a8600/public"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
          </b-carousel>
      </div>
  
      <div style="padding-left: 10px; padding-right: 10px;">
        <NoticeBar
          class="col-lg-12 col-12 mx-auto notice-bar"
          scrollable
          :text="websiteInfo[0]?.noticeText"
          v-if="!threedData.embedded_link && !threedData.isLive"
        />
        
      </div>
  
      <div class="col-12 account-container-wrapper d-flex py-3 px-4 my-4">
        <div class="col-lg-6 col-6 my-auto">
          <!-- <img src="https://cdn.gold549.com/build/assets/img/theme/profile.png" style="width: 92px; height: 92px" alt=""> -->
            <div class="d-flex flex-column">
              <div style="font-size: 1rem">
                  {{
                      $store.state.language === "en"
                          ? "Balance"
                          : "လက်ကျန်ငွေ"
                  }}
              </div>
              <div style="color: #ffffff; font-weight: 400; font-size: 1.3rem;">
                <b>{{ Number($store.state.amount).toLocaleString() }}</b> <span class="text-sm">Unit</span>
              </div>
            </div>
        </div>
  
        <div class="col-lg-6 col-6">
          <div class="d-flex flex-column justify-content-end">
            <div class="text-right" style="font-size: 1rem"> 
                {{
                    $store.state.language === "en"
                        ? "Username"
                        : "အမည်"
                }}
            </div>
            <div class="text-right" style="color: #ffffff; font-weight: 400; font-size: 1.2rem;">{{$store.state.authUser.name}} </div>
          </div>
        </div>
      </div>
  
      <div class="justify-content-center cards-container-mobile px-2">
        <div
          class="col-xl-6 col-6 home-cards pr-1"
          @click="$router.push('user/2d')"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-2 pt-2 flex-column"
            style="width: 100%; height: 6rem; background: #222222; border-radius: 2.2rem; border: 1.1px solid #FF5800;"
          >
            <div>
              <img
                src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/icons/17.png"
                alt=""
                style="width: auto; height: 3rem"
              />
            </div>
            <div class="pt-1">
              <h4 class="text-white">
                  {{ $t('2d') }}
              </h4>
            </div>
          </div>
        </div>
  
        <div
          class="col-xl-6 col-6 home-cards pl-1"
          @click="$router.push('user/2d/home')"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-2 pt-2 flex-column"
            style="width: 100%; height: 6rem; background: #222222; border-radius: 2.2rem; border: 1.1px solid #FF5800;"
          >
            <div>
              <img
                src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/icons/20.png"
                alt=""
                style="width: auto; height: 3rem"
              />
            </div>
            <div class="pt-1">
              <h4 class="text-white">
                {{ $t('2d_result') }}
              </h4>
            </div>
          </div>
        </div>

        <div
          class="col-xl-6 col-6 home-cards pr-1"
          @click="$router.push('user/3d')"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-2 pt-2 flex-column"
            style="width: 100%; height: 6rem; background: #222222; border-radius: 2.2rem; border: 1.1px solid #FF5800;"
          >
            <div>
              <img
                src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/icons/18.png"
                alt=""
                style="width: auto; height: 3rem"
              />
            </div>
            <div class="pt-1">
              <h4 class="text-white">
                {{ $t('3d') }}
              </h4>
            </div>
          </div>
        </div>
  
        <div
          class="col-xl-6 col-6 home-cards pl-1"
          @click="$router.push('user/3d/home')"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-2 pt-2 flex-column"
            style="width: 100%; height: 6rem; background: #222222; border-radius: 2.2rem; border: 1.1px solid #FF5800;"
          >
            <div>
              <img
                src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/icons/19.png"
                alt=""
                style="width: auto; height: 3rem"
              />
            </div>
            <div class="pt-1">
              <h4 class="text-white">
                {{ $t('3d_result') }}
              </h4>
            </div>
          </div>
        </div>
      </div>
        
      <div class="mt-4 d-flex">
          <button class="btn btn-success text-white col-4 mx-auto" style=" width: 100%; border-color: #ff5400 !important; background: #ff5400 !important; border-radius: 1rem;"  @click="$playSound(); $router.push('/home')">
              <div>
                {{ $t('back_to_home') }}
              </div>
          </button>
      </div>
        
    </div>
  </template>
  <script>
  import { NoticeBar, Sticky } from 'vant';
  import { mapActions } from 'vuex';
  
  export default {
    components: { NoticeBar, Sticky },
    data() {
      return {
        twoDdata: {},
        twoDHistories: {},
        threeDHistories: {},
        threedData: {},
        websiteInfo: {},
      };
    },
    methods: {
        ...mapActions(['fetchUser']),
        async fetchWebsiteData() {
          try {
            const res = await axios.get('/website-infos');
            this.websiteInfo = res.data.data;
          } catch (error) {
            console.log(error);
        }
      },
    },
    async mounted() {
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
    //   console.log(authUser)
      this.fetchWebsiteData();
    //   this.fetchUser();
      // this.fetchTwoDdata();
      // this.fetchThreedDdata();
      // this.fetchTwoThreeDHistories();
    },
  };
  </script>
  <style scoped>
  .css-38745a {
      margin: 0px;
      line-height: 1.5;
      font-size: 12px;
      /* font-family: NotoSansMyanmar; */
      font-weight: 500;
  }
  .css-17h40hp {
      margin: 0px;
      line-height: 1.5;
      font-size: 14px;
      /* font-family: NotoSansMyanmar; */
      font-weight: 700 !important;
  }
  .card {
    cursor: pointer;
    border-radius: 10px !important;
  }
  .main-image-wrapper img {
    width: 100%;
  }
  .video-container {
    height: 500px;
    width: 100%;
  }
  .notice-bar {
    margin-top: -40px;
    z-index: 1;
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    background: rgba(255, 242, 0, 0.7);
  }
  .twod-threed-cards {
    height: 200px;
  }
  .live-number {
    font-weight: bold;
    font-size: 25px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .not-live-text {
    font-weight: bold;
    font-size: 24px;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
  }
  .card-body {
    padding: 0px !important;
  }
  .cards-container-mobile {
    display: none;
  }
  @keyframes live-number-animation {
    from {
      color: white;
    }
    to {
      color: red;
    }
  }
  
  /* @media (max-width: 768px) { */
    .middle-container {
      /* padding-top: 0.1rem !important; */
    }
    hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .video-container {
      height: 300px;
      width: 100%;
    }
    .main-wrapper {
      padding: 7px !important;
    }
    .notice-bar {
      margin-top: 0px;
      height: 2.5rem;
      z-index: 1;
      font-size: 1rem;
      color: #ffffff;
      background: #1A1A1A;
      border-radius: 7px;
    }
    .live-number {
      font-weight: bold;
      font-size: 20px;
    }
    .live-result {
      font-weight: bolder;
      font-size: 37px;
    }
  
    .cards-container {
      display: none;
    }
    .cards-container-mobile {
      /* padding: 0px !important; */
      margin-top: 5px !important;
      display: flex;
      flex-wrap: wrap;
    }
    .home-cards {
      padding-left: 10px;
      padding-right: 10px;
    }
    .card-body {
      padding: 0px !important;
    }
    .not-live-text {
      font-size: 18px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .account-container-wrapper {
      width: auto;
      height: auto;
      border-radius: 1.5rem;
      padding-left: 4px;
      padding-right: 4px;
      margin: 10px;
      color: white;
      background: #FF5800;
      /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
      box-shadow: none;
    }
    .top-wrapper,
    .bottom-wrapper {
      padding: 10px;
      width: 100%;
      display: flex;
    }
    .top-right,
    .bottom-right {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: end;
    }
    .top-left,
    .bottom-left {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: start;
    }
  /* } */
  </style>
  