var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 py-4 bg-dark"},[_c('h1',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-book mr-2 my-1"}),_vm._v(" "+_vm._s(_vm.$store.state.language === "en" ? "Rules And Regulations" : "စည်းကမ်းချက်များ")+" ")]),_c('ul',{staticClass:"text-white font-weight-300"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('li',[_c('div',{staticClass:"d-flex justify-content-center my-4",staticStyle:{"position":"fixed","bottom":"0","left":"0","right":"0"}},[_c('button',{staticClass:"btn btn-danger col-4",staticStyle:{"font-size":"1rem","background":"black","color":"white !important"},on:{"click":_vm.logout}},[_vm._v("အတည်မပြုပါ")]),_c('button',{staticClass:"btn col-4",staticStyle:{"font-size":"1rem","background":"#009646 !important","color":"white !important"},on:{"click":_vm.toHome}},[_vm._v("အတည်ပြုပါသည်")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၁) ")]),_vm._v("ဘော်ဒီ ၊ ဂိုးပေါင်း ၊ စုံမ ပွဲစဉ်များကို (⭐) ဖြင့် ပွဲကြီး / ပွဲသေး ခွဲခြားထားပြီး "),_c('br'),_vm._v(" "),_c('br'),_c('span',{staticClass:"ml-4"},[_vm._v("ပွဲကြီး အကောက် - "),_c('span',{staticClass:"text-danger"},[_vm._v("5%")]),_vm._v(" ( ၁၀၀၀ ယူနစ်မှ ၁၅ သိန်း ယူနစ် အထိ )")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"ml-4"},[_vm._v("ပွဲသေး အကောက် - "),_c('span',{staticClass:"text-danger"},[_vm._v("7%")]),_vm._v(" ( ၁၀၀၀ ယူနစ်မှ ၃ သိန်း ယူနစ်အထိ ) ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"ml-4"},[_vm._v("ဆော့ကစားနိုင်ပါသည်။")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၂) ")]),_vm._v("မောင်းများကို အနည်းဆုံး ၂ ပွဲမှ ၁၂ ပွဲအထိ ဆော့ကစားနိုင်ပြီး ၊ မောင်းတစ်ရွက်လျှင် ၅၀၀ ယူနစ်မှ ၂ သိန်း ယူနစ်အထိ ဆော့ကစားနိုင်ပါသည်။ ( ပွဲမလာ ၊ ပွဲပျက် ၊ PP ပွဲများပါခဲ့လျှင် ကျန်ပွဲဖြင့် အလျော်အစားပြုလုပ်ပါမည်။ ) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၃) ")]),_vm._v("၂သင်းမှ ၈သင်းထိ အကောက် - "),_c('span',{staticClass:"text-danger"},[_vm._v("16 %")]),_vm._v(" နှင့် ၉ သင်း နှင့်အထက် - "),_c('span',{staticClass:"text-danger"},[_vm._v(" 20%")]),_vm._v(" ကောက်ခံပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၄) ")]),_vm._v("ရွေးချယ်ထားသောမောင်းများမှ ပွဲစဉ်များအားလုံး မကန်ဖြစ်မှသာ လောင်းကြေးငွေအား ပြန်လည်ရရှိမည် ဖြစ်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၅) ")]),_vm._v(" သတ်မှတ်ရက်အတွင်း နောက်တစ်နေ့ 10:30am အတွင်း result (ပွဲစဉ်မပြီးသော) မထွက်သေးသောပွဲစဉ်များအား အလျော်အစား ပြုလုပ်ပေး မည်မဟုတ်ပါ။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၆) ")]),_vm._v(" ဂိုးရလာဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting company (ibet789,bet365,sportbook365) များမှထွက်ရှိသောရလာဒ်များ ဖြင့်သာ အလျော်အစားပြုလုပ်ပြီး အဆိုပါကုမ္မဏီများမှ 9:00am အထိ မထွက်ရှိပါက flashscore..com, livescore..com တို့အား ကြည့်ရှူပြီး မတွေ့ရှိပါက သက်ဆိုင်ရာအဖွဲ့ချုပ်၏ ရလာဒ်များကိုသာအတည်ပြုပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၇) ")]),_vm._v("ဂိုးရလာဒ်များထည့်သွင်းရာတွင်အချို့ပွဲစဥ်များသည် ညပိုင်းတွင်ရလာဒ်တစ်မျိုးထွက်ပေါ်ပြီး မနက်ပိုင်းတွင်ရလာဒ်တစ်မျိုးဖြင့် ပြင်ဆင်ပြောင်းလဲထွက်ပေါ်လာပါက နေ့ခင်း ၁၂ နာရီအထိသာပေးပို့အကြောင်းကြားလာသည့်ဘောင်ချာများကိုသာပြင်ဆင်ပြောင်းလဲ ပေးပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၈) ")]),_vm._v("အချိန်စောကန်သွားသော ပွဲစဥ်များရှိပါက အခြား Betting company များနည်းတူမဟုတ်ပဲ Aon637 တွင်မူလအချိန်အတိုင်း ဖွင့်လှစ်ထားမိပါက အဆိုပါပွဲများအား Cancel match ပြုလုပ်ပါမည်။အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၉) ")]),_vm._v("ပုံမှန်မဟုတ်သော ပွဲစဥ်များ ပါရှိပါက ၄င်းပွဲစဥ်များအား အလျော်အစားမပြုလုပ်ပဲ Cancel Match ပြုလုပ်ပြီး လောင်းကြေးငွေ ပြန်အမ်း ပေးပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၁၀) ")]),_vm._v("သာမန်ကစားခြင်းမဟုတ်ပဲ ကြေးအဟ သီးသန့်ကြားထိုး ကစားပါက အဆိုပါ User ၏ကြားထိုး ကစားကြောင်းအား reject ပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၁၁) ")]),_vm._v("နည်းပညာချွတ်ယွင်း၍ အခြားသော Betting Company များနှင့်စျေးနှုန်း ပေါက်ကြေး များ (လွန်ကဲစွာ) ကွဲလွဲဖွင့်လှစ်မိပါက ယင်းစျေးနှုန်း ဖြင့် ကစားထားသော မောင်းနှင့်ဘော်ဒီများကို Cancel ပြုလုပ်ပြီး အရင်းငွေများကိုပြန်ပေးပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger mr-3"},[_vm._v(" (၁၂) ")]),_vm._v("အငြင်းပွားဖွယ်ရာ ကိစ္စရပ်များပေါ်ပေါက်လာပါက အများနှင့် နှိုင်းယှဥ်သုံးသပ်ရသည့်အတွက် Aon637 ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h5',{staticClass:"text-center text-danger mt-4 mb-5 pb-3"},[_vm._v(" စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။ ")])])
}]

export { render, staticRenderFns }